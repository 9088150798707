.benefit-item {
    border-radius: 10px;
    text-align: center;
    font-size: 1rem;
    padding: 10px 15px;
    margin-bottom: 10px;
    border: 1px solid white;
    max-width: 400px;
    width: 90%;
}

.benefit-title {
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 1.5rem;
}

.benefit-description {
    margin-bottom: 8px;
    font-size: 1.3rem;
}

.benefits-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Расстояние между блоками */
    justify-content: center; /* Центрирование элементов по горизонтали */
}

@media (max-width: 600px) {
    .benefits-list {
        flex-direction: column; /* На мобильных устройствах располагаем блоки вертикально */
        align-items: center; /* Центрируем элементы по центру экрана */
    }
}
