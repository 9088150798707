/* Скрытие стандартного скролл-бара */
body::-webkit-scrollbar {
    width: 12px;
    /* Ширина скролл-бара */
}

/* Стилизация скролл-бара */
body::-webkit-scrollbar-track {
    background-color: #1E1E1E;
    /* Цвет трека */
}

body::-webkit-scrollbar-thumb {
    background-color: #040814;
    /* Цвет ползунка */
    border-radius: 6px;
    /* Радиус закругления ползунка */
}

/* Изменение внешнего вида при наведении на ползунок */
body::-webkit-scrollbar-thumb:hover {
    background-color: #d08927;
    /* Цвет ползунка при наведении */
}

/* Стилизация скролл-бара в горизонтальном режиме */
body::-webkit-scrollbar-horizontal {
    height: 12px;
    /* Высота скролл-бара в горизонтальном режиме */
}

body::-webkit-scrollbar-thumb:horizontal {
    background-color: #040814;
    /* Цвет ползунка в горизонтальном режиме */
}

/* Стилизация скролл-бара в вертикальном режиме */
body::-webkit-scrollbar-vertical {
    width: 12px;
    /* Ширина скролл-бара в вертикальном режиме */
}

body::-webkit-scrollbar-thumb:vertical {
    background-color: #040814;
    /* Цвет ползунка в вертикальном режиме */
}

@font-face {
    font-family: 'Golos';
    src: url('/src/assets/fonts/Golos Text_Medium.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Syncopate';
    src: url('/src/assets/fonts/Syncopate Bold Cyr.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-size: cover;
    /*background-position: center center;*/
    /*background-image: url("/src/assets/images/восемь_пнг.png");*/
    filter: hue-rotate(60deg) opacity(60%);
    -webkit-filter: hue-rotate(60deg) opacity(60%);
    -moz-filter: hue-rotate(60deg) opacity(60%);
    -o-filter: hue-rotate(60deg) opacity(60%);
    /*background: url("/src/assets/images/fire.png"), url("/src/assets/images/shadow.png"), url("/src/assets/images/light.png"), url("/src/assets/images/восемь_пнг.png") no-repeat center center;*/
    transition: transform 0.5s ease-in-out;
}

html {
    font-size: 14px;
    background-color: #050723;
    line-height: 1.5;
}

body {
    /*color: #C6D7DA;*/
    color: white;
    font-family: "Golos", sans-serif;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
}

.tg-popup {
    position: fixed;
    z-index: 100;
    background-color: black;
    font-size: 1.3rem;
    color: #007acd;
    bottom: 2rem; /* Отступ от нижнего края */
    right: 2rem; /* Отступ от правого края */
    padding: 10px 20px; /* Внутренние отступы */
    border: 1px solid white; /* Граница вокруг блока */
    border-radius: 20px;
    height: 60px;
    text-align: center;
    vertical-align: center;
    opacity: 0; /* Начальная прозрачность */
    transform: scale(0); /* Начальный масштаб */
    transition: transform 1s ease-in-out, opacity 1s ease-in-out, width 0.5s, height 0.5s, border-radius 0.5s, padding 0.5s; /* Плавный переход для всех свойств */
}

.tg-popup.visible {
    opacity: 1; /* Прозрачность для видимого состояния */
    transform: scale(1); /* Масштаб для видимого состояния */
}

.tg-popup.compressed {
    width: 60px; /* Ширина для сжатого состояния */
    height: 60px; /* Высота для сжатого состояния */
    border-radius: 50%; /* Становится круглым */
    padding: 0; /* Убираем внутренние отступы */
    display: flex;
    justify-content: center;
    align-items: center;
    /*transition-delay: 2s;*/
}

.tg-popup.compressed .telegram-icon {
    font-size: 1.5rem; /* Размер иконки */
    color: #007acd; /* Цвет иконки */
}

.tg-popup:hover {
    width: auto; /* Восстанавливаем ширину */
    height: auto; /* Восстанавливаем высоту */
    border-radius: 20px; /* Восстанавливаем радиус */
    padding: 10px 20px; /* Восстанавливаем внутренние отступы */
    font-size: 1.3rem; /* Восстанавливаем размер текста */
}

@media (max-width: 767px) {
    html {
        font-size: 14px;
        line-height: 1.4;
    }
}

section {
    padding: 1rem 0.5rem 0 0.5rem;
}

.cool-header {
    height: 100vh;
    background-color: #040814;
    color: white;
    padding: 20px;
    font-size: 3rem;
}

.ar {
    fill: white;
    width: 40px;
    height: 40px;
}

.block-dod {
    height: 85vh;
    width: auto;
    /*background-color: #040814;*/
    display: flex;
    flex-direction: column;
    /*padding: 50px 0 50px 100px;*/
    z-index: -1;
    background: #040814 url("/src/assets/images/1920_1080_син.png") bottom 80% right 100% no-repeat;
    /*background-size: 90% auto;*/
}

.block-dod-slogan-container {
    padding: 10px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.block-dod-slogan {
    font-size: 2rem;
    font-weight: 500;
    margin-right: 5rem;

    display: flex;
    align-items: center;
}

.block-dod-slogan-accent {
    font-weight: 900;
    font-size: 2.5rem;
    text-shadow: #007acd 2px 0 8px, #007acd -2px 0 8px;
    margin-left: 10px;
}

.block-dod-main {
    padding-left: 50px;
    padding-bottom: 50px;
    padding-top: 25px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1
}

.block-dod-date {
    width: max-content;
    background: rgba(0, 122, 205, 0.63);
    color: white;
    padding: 5px 35px;
    border-radius: 30px;
    font-size: 1.5rem;
}

.block-dod-name {
    font-size: 4rem;
    font-weight: 700;
    width: 40%;
    line-height: 1;
}

.block-dod-inst {
    color: white;
    font-size: 2rem;
    font-weight: 500;
    width: 40%;
    line-height: 1;
}

.block-dod-logo--main {
    font-size: 1.3rem;
    width: max-content;
    padding: 10px 30px;
    border: solid white 2px;
    border-radius: 30px;
}

.block-dod-logo {
    font-size: 1.3rem;
    width: max-content;
    border: solid white 2px;
    padding: 5px 35px;
    border-radius: 30px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.block-dod-logo.button {
    font-size: 2.5rem;
    font-weight: 700;
    cursor: pointer;
    color: black;
    width: max-content;
    padding: 10px 30px;
}

h1 {
    font-size: 4rem;
    font-family: "Syncopate", sans-serif;
    font-weight: 900;
    color: #007ACD;
    line-height: 1.2;
    margin-bottom: 1rem;
    margin-left: 5%;
    text-transform: uppercase;
}

h2 {
    font-family: "Syncopate", sans-serif;
    color: white;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    text-transform: uppercase;
}

h3 {
    font-family: "Golos", sans-serif;
    color: white;
    font-weight: 900;
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-transform: uppercase;
    text-align: center;
}


@media (max-height: 600px) {
    .block-dod {
        margin-top: 3rem;
        height: 75vh;
    }

    .block-dod-name {
        font-size: 1.9rem;
    }

    .block-dod-inst {
        font-size: 1.25rem;
    }

    .block-dod-logo {
        font-size: 0.9rem;
    }

    .block-dod-logo.button {
        font-size: 1.6rem;
    }

    .block-dod-date {
        font-size: 1rem;
    }

    .block-dod {
        background: #040814 url("/src/assets/images/1920_1080_син.png") bottom 90% right 80% no-repeat;
    }
}

@media (max-height: 500px) {
    .block-dod {
        margin-top: 3.5rem;
        height: 75vh;
    }

    .block-dod-name {
        font-size: 1.2rem;
        width: 70%;

    }

    .block-dod-inst {
        font-size: 1.1rem;
        width: 80%;
    }

    .block-dod-logo {
        font-size: 0.7rem;
        padding: 5px 15px;
        border-radius: 20px;
    }

    .block-dod-logo.button {
        font-size: 1.5rem;
    }

    .block-dod-date {
        padding: 5px 25px;
        font-size: 0.9rem;
    }

    .block-dod {
        background: #040814 url("/src/assets/images/1920_1080_син.png") bottom 90% right 80% no-repeat;
    }

    h1 {
        font-size: 3.3rem;
    }
}


@media (max-width: 900px) and (min-height: 600px) {
    .block-dod-name {
        font-size: 3.5rem;
    }

    .block-dod-inst {
        font-size: 1.8rem;
    }

    .block-dod {
        background: #040814 url("/src/assets/images/1920_1080_син.png") bottom 80% right 80% no-repeat;
    }

    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.5rem;
    }
}

@media (max-width: 600px) and (min-height: 600px) {
    .block-dod-logo.button {
        font-size: 1.6rem;
    }


    .block-dod-name {
        font-size: 3.2rem;
    }

    .block-dod-inst {
        font-size: 1.5rem;
    }


    .block-dod {
        background: #040814 url("/src/assets/images/1920_1080_син.png") bottom 80% right 70% no-repeat;
    }

    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.5rem;
    }
}

@media (max-width: 484px) and (min-height: 600px) {
    .block-dod-logo.button {
        font-size: 1.4rem;
    }

    .block-dod-name {
        font-size: 3rem;
    }

    .block-dod-inst {
        font-size: 1.5rem;
    }


    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.5rem;
    }
}

@media (max-width: 380px) {
    .block-dod-logo.button {
        font-size: 1.3rem;
    }


    h1 {
        font-size: 2.3rem;
    }
}


@keyframes typing {
    /*0.0%, 25.0% { content: ""; }*/
    /*1.0%, 24.0% { content: "р"; }*/
    /*2.0%, 23.0% { content: "ра"; }*/
    /*3.0%, 22.0% { content: "раз"; }*/
    /*4.0%, 21.0% { content: "разр"; }*/
    /*5.0%, 20.0% { content: "разра"; }*/
    /*6.0%, 19.0% { content: "разраб"; }*/
    /*7.0%, 18.0% { content: "разрабо"; }*/
    /*8.0%, 17.0% { content: "разработ"; }*/
    /*9.0%, 16.0% { content: "разработк"; }*/
    /*10.0%, 15.0% { content: "разработка"; }*/
    /*25.0%, 50.0% { content: ""; }*/
    /*26.428571428571427%, 48.57142857142857% { content: "б"; }*/
    /*27.857142857142854%, 47.14285714285714% { content: "бу"; }*/
    /*29.28571428571428%, 45.71428571428571% { content: "буд"; }*/
    /*30.714285714285708%, 44.28571428571428% { content: "буду"; }*/
    /*32.14285714285714%, 42.85714285714285% { content: "будущ"; }*/
    /*33.57142857142857%, 41.428571428571416% { content: "будуще"; }*/
    /*35.0%, 39.999999999999986% { content: "будущее"; }*/
    /*50.0%, 75.0% { content: ""; }*/
    /*51.111111111111114%, 73.88888888888889% { content: "и"; }*/
    /*52.22222222222223%, 72.77777777777777% { content: "ин"; }*/
    /*53.33333333333334%, 71.66666666666666% { content: "инн"; }*/
    /*54.44444444444446%, 70.55555555555554% { content: "инно"; }*/
    /*55.55555555555557%, 69.44444444444443% { content: "иннов"; }*/
    /*56.666666666666686%, 68.33333333333331% { content: "иннова"; }*/
    /*57.7777777777778%, 67.2222222222222% { content: "инновац"; }*/
    /*58.888888888888914%, 66.11111111111109% { content: "инноваци"; }*/
    /*60.00000000000003%, 64.99999999999997% { content: "инновации"; }*/
    /*75.0%, 100.0% { content: ""; }*/
    /*77.0%, 99.0% { content: "I"; }*/
    /*78.0%, 98.0% { content: "IT"; }*/
    /*79.0%, 97.0% { content: "IT."; }*/

    0.0%,
    14% {
        content: "";
    }

    2%,
    12% {
        content: "Я";
    }

    16.0%,
    34.0% {
        content: "";
    }

    18%,
    32% {
        content: "Т";
    }

    20.5%,
    30.5% {
        content: "ТЫ";
    }

    40.0%,
    54% {
        content: "";
    }

    42%,
    52% {
        content: "М";
    }

    44%,
    50% {
        content: "МЫ";
    }

    60.0%,
    100.0% {
        content: "";
    }

    62%,
    98% {
        content: "I";
    }

    64%,
    96% {
        content: "IT";
    }

    66%,
    94% {
        content: "IT.";
    }
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.typewriter {
    --caret: currentcolor;
}

.typewriter::before {
    content: "";
    animation: typing 10s infinite;
}

.typewriter::after {
    content: "";
    animation: blink 0.6s linear infinite;
}

.typewriter {
    color: #f534dc;
}

.typewriter.thick::after {
    border-right: 10px solid #f534dc;
}

.typewriter.nocaret::after {
    border-right: 0;
}

.container {
    max-width: 2500px;
    margin: 0 auto;
    scroll-snap-type: y mandatory;
}

.section-title {
    color: #007ACD;
}

.section-text {
    padding-right: 3rem;
    padding-left: 3rem;
    font-size: 1.4rem;
}

@media (max-width: 768px) {
    .section-text {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.contact-block {
    display: flex;
    flex-wrap: wrap;
}

.text-block {
    max-width: 100%;
    margin-right: 10px;
    /* Отступ между блоками */
}

.map-block {
    z-index: 1;
    flex: 0 0 calc(50% - 10px);
    /* Ширина блока с картой (50% минус отступы) */
    max-width: 100%;
    margin-left: 10px;
    display: flex;
}

@media (min-width: 1050px) {
    .contact-block {
        flex-wrap: nowrap;
    }

    .text-block {
        order: 1;
        margin-right: 20px;
    }

    .map-block {
        order: 2;
        width: auto;
    }
}

.m_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    max-width: 600px;
    margin: 0 auto;
}

.m_highlighted-text {
    position: relative;
    border: 3px solid #007acd;
    padding: 20px;
    font-weight: bold;
    text-align: center;
    border-radius: 15px;
}

.m_highlighted-text::before {
    content: "\f12a";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    top: -15px;
    right: -12px;
    font-size: 24px;
    color: #007acd;
}

/* Стили для слайдера */
.slider {
    width: 80%;
    /* Ширина слайдера */
    height: 400px;
    /* Высота слайдера */
    overflow: auto;
    /* Включение скролла */
}

/* Стили для слайдов внутри слайдера */
.slider-slide {
    width: 100%;
    /* Ширина слайда */
    height: 100%;
    /* Высота слайда */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
}

/* Скрытие стандартного скролл-бара */
.slider::-webkit-scrollbar {
    width: 12px;
    /* Ширина скролл-бара */
}

/* Стилизация скролл-бара */
.slider::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: #1E1E1E;
    /* Цвет трека */
}

.slider::-webkit-scrollbar-thumb {
    background-color: #636583;
    /* Цвет ползунка */
    border-radius: 6px;
    /* Радиус закругления ползунка */
}

/* Изменение внешнего вида при наведении на ползунок */
.slider::-webkit-scrollbar-thumb:hover {
    background-color: #d08927;
    /* Цвет ползунка при наведении */
}

/* Стилизация скролл-бара в горизонтальном режиме */
.slider::-webkit-scrollbar-horizontal {
    height: 12px;
    /* Высота скролл-бара в горизонтальном режиме */
}

.slider::-webkit-scrollbar-thumb:horizontal {
    background-color: #636583;
    /* Цвет ползунка в горизонтальном режиме */
}

/* Стилизация скролл-бара в вертикальном режиме */
.slider::-webkit-scrollbar-vertical {
    border-radius: 6px;
    width: 12px;
    /* Ширина скролл-бара в вертикальном режиме */
}

.slider::-webkit-scrollbar-thumb:vertical {
    background-color: #636583;
    /* Цвет ползунка в вертикальном режиме */
}

.highlight-content {
    background-color: #000000;
    border-radius: 15px;
    padding: 20px;
}


/*.button-container_sec {*/
/*  display: flex;*/
/*  flex-wrap: wrap;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  margin-top: 20px;*/
/*  margin-bottom: 20px;*/
/*  flex-direction: column;*/
/*}*/

/*@media (min-width: 768px) {*/
/*  .button-container_sec {*/
/*    flex-direction: row;*/
/*  }*/
/*}*/

.button_sec {
    text-align: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 900;
    font-size: 1.2rem;
    border-radius: 6px;
    padding: 20px;
    border: none;
    font-family: "Syncopate", sans-serif;
    text-transform: uppercase;
}

/*@media (min-width: 768px) {*/
/*  .button_sec {*/
/*    margin-bottom: 0;*/
/*    margin-right: 20px;*/
/*  }*/
/*}*/

.marquee_wrapper {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: auto;
    max-width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    position: relative;
    margin-left: auto;
    opacity: 1;
    gap: 10px;
    overflow: hidden;

}

.marquee_items {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: max-content;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    min-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-animation: marquee 50s infinite linear;
    animation: marquee 50s infinite linear;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    gap: 10px;
}

.marquee_item {
    /*min-width: -webkit-max-content !important;*/
    /*min-width: -moz-max-content !important;*/
    min-width: max-content !important;
    padding: 21px 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 15px;
    border: 2px solid #C6D7DA;
    background-color: #040814;
    color: #f534dc;
    font-weight: 900;
    font-size: 1rem;
    font-family: "Syncopate", sans-serif;
    text-transform: uppercase;
    gap: 10px;
}

.d_description {
    font-size: 1rem;
}

.v_description {
    font-size: 1.3rem;
    padding-left: 5px;
}

.dv_description {
    font-size: 1rem;
    padding-left: 5px;
}

.p_description {
    margin: 0.5rem;
    font-weight: 400;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
}

.sber-title {
    color: #21ba72;
}

.t1-title {
    color: #007aff;;
}

section p {
    /*gap: 5rem;*/
    margin-bottom: 2rem;
}

@media (min-width: 1024px) {
    .d_description {
        font-size: 1rem;
    }

    .v_description {
        font-size: 1.6rem;
    }

    .dv_description {
        font-size: 1rem;
    }

    section {
        padding: 1rem 1.5rem 0 1.5rem;
    }
}

.scroll-item {
    scroll-snap-align: start;
}


.section {
    margin-bottom: 20px;
}

.program-group-name {
    font-size: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 1.2;
    color: #90d3f9;
}

.program-group-feature {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.feature {
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    padding: 10px 15px;
    margin-bottom: 10px;
    border: white solid 1px;
    line-height: 1.2;
}

.feature-number {
    font-size: 1.6rem;
    display: block;
}

.feature-description {
    display: block;
}

.programs {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
}

.program {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: white solid 1px;
}

.program-name {
    flex: 3;
    font-size: 1em;
    line-height: 1;
    padding-top: 5px;
    padding-bottom: 5px;
}

.program-code, .department {
    flex: 1;
    text-align: center;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 5px;
}

section .section-text {
    padding-left: 0;
    padding-right: 0;
}