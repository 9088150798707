footer {
    background-color: #040814;
    color: #C6D7DA;
    padding: 20px;
}

.footer-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 30px;
}

.footer-title {
    font-weight: 900;
    font-size: 1.3rem;
    text-transform: uppercase;
    font-family: "Syncopate", sans-serif;
}

.sitemap,
.contacts {
    width: calc(33.33% - 10px);
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.social {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.social-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    /*display: table-cell;*/
    align-items: center;
}

.social-icon {
    height: 30px;
    width: auto;
    padding: 10px;
}

@media (max-width: 1000px) {
    .social {
        flex-direction: column;
    }
    .footer-title-container {
        flex-direction: column;
        gap: 50px;
    }
}

.sitemap h3,
.social h3,
.contacts h3 {
    font-size: 1rem;
    color: #C6D7DA;
    padding: 0;
}

.sitemap ul,
.social ul {
    list-style-type: none;
    padding: 0;
}

.sitemap ul li,
.social ul li {
    color: #C6D7DA;
    margin-bottom: 5px;
    font-size: 1.6rem;
}

.sitemap ul li:hover,
.social ul li:hover {
    text-decoration: underline;
    color: #c5c5c5;

}

.sitemap ul li a,
.social ul li a {
    color: #C6D7DA;
    text-decoration: none;
}

.sitemap ul li a:hover,
.social ul li a:hover {
    text-decoration: underline;
    color: #c5c5c5;
}

.legal-info {
    margin: 0 auto;
}

.legal-info p {
    color: #C6D7DA;
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
}

footer a {
    color: #C6D7DA;
    text-decoration: none;
    font-size: 1.5rem;
}

footer a:hover {
    text-decoration: underline;
    color: #c5c5c5;
}

.contacts p {
    padding: 0;
    color: #C6D7DA;
    font-size: 1.5rem;
}

/* Адаптивные стили */
@media (max-width: 768px) {
    .footer_container {
        flex-direction: column;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .sitemap,
    .social,
    .contacts {
        width: 100%;
    }
}